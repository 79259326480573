import React, { useState } from "react";

import { LazyImageIntersectionObserver } from "./LazyImageIntersectionObserver";
import { LazyImageIntersectionObserverContext } from "./LazyImageIntersectionObserverContext";

export const LazyImageIntersectionObserverProvider = (props) => {
  const [observer, setObserver] = useState(LazyImageIntersectionObserver);
  return (
    <LazyImageIntersectionObserverContext.Provider value={observer}>
      {props.children}
    </LazyImageIntersectionObserverContext.Provider>
  );
};
